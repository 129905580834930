export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS

    // Check if a specific id is in view and fire the CountUp Function
    var counter = 0;
    const numberOfStats = $('#home-company-statistics .stat-item-wrp').length+1;

    var observer = new IntersectionObserver(function(entries) {
      if(entries[0].isIntersecting === true)
        if (counter == 0) {
          setTimeout(function(){
            counter++;
            for (let i = 1; i < numberOfStats; i++) {
              document.querySelector('#final-'+ i).classList.remove('d-none');
              document.querySelector('#initial-'+ i).classList.add('d-none');
            }
            countUp();
          }, 200);
        }
    }, { threshold: [1] });

    observer.observe(document.querySelector('#home-company-statistics'));
  },
};

function countUp() {
  $('.count').each(function () {
    $(this)
      .prop('Counter', 0)
      .animate(
        {
          Counter: $(this).text(),
        },
        {
          duration: 2000,
          easing: 'swing',
          step: function (now) {
            now = Number(Math.ceil(now)).toLocaleString('en');
            $(this).text(now);
          },
        }
      );
    }
  );
}
