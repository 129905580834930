import 'flickity/dist/flickity.pkgd.js';
import Flickity from 'flickity';
import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';
import 'select2/dist/js/select2.min.js';

// Uses as the variable for 'Initiate Load More' script
// let loadMoreItems = 6;

// sticky header variable
var shrinkHeader = 5;

Flickity.prototype._createResizeClass = function() {
  this.element.classList.add('flickity-resize');
};

Flickity.createMethods.push('_createResizeClass');

var resize = Flickity.prototype.resize;
Flickity.prototype.resize = function() {
  this.element.classList.remove('flickity-resize');
  resize.call( this );
  this.element.classList.add('flickity-resize');
};

export default {
  init() {
    // JavaScript to be fired on all pages

    Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired

    const slideshowElements = document.querySelectorAll('.logo-section .logo-slider');
    headerScripts();
    logoSectionGridSlider();
    slideshowElements.forEach(element => {
      logoSectionSlider(element);
    });
    formScripts();
    cardSectionGridSlider();
    cardSectionSlider();
    cardSectionGridSliderNew();
    cardSectionSliderNew();
    gallerySectionSlider();
    jqueryViewer('#gallery-images-viewer-v2');
    jqueryViewer('#gallery-images-viewer-v1');
    fullWidthVideoPlayer();
    videoPopupPlayer();
    delayHomeBannerVideo();
    wrapButtons();
    loadMore();
    testimonialGridSlider();
    testimonialSlider();
  },
};

$(window).on('resize', function () {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp, .cards-section.v2 .section-inner-wrp .cards-wrp, .cards-section.v3 .section-inner-wrp .cards-wrp, .gallery-section.v1 .section-inner-wrp .gallery-wrapper, .cards-section-new.slider .section-inner-wrp .cards-wrp, .cards-section-new.grid .section-inner-wrp .cards-wrp, .testimonials-section .testimonials-row'
  ).flickity('resize');

  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());
});

$(window).on('load', function () {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp, .cards-section.v2 .section-inner-wrp .cards-wrp, .cards-section.v3 .section-inner-wrp .cards-wrp, .gallery-section.v1 .section-inner-wrp .gallery-wrapper, .cards-section-new.slider .section-inner-wrp .cards-wrp, .cards-section-new.grid .section-inner-wrp .cards-wrp, .testimonials-section .testimonials-row'
  ).flickity('resize');
});

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function headerScripts() {
  // toggle class on hamburger icon click
  $('.mobile-burger-icon').click(function () {
    $('body, .mobile-burger-icon').toggleClass('show-menu');
  });

  // mobile sub menu navigation
  // level 1
  $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
    $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
    $(this).find('> a').click(function (e) {
      e.preventDefault();
      $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
    });
  });

  // level 2
  $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function () {
    $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
    $(this).find('> a').click(function (e) {
      e.preventDefault();
      $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
    });
  });

  // return link click
  $('.return-link').each(function () {
    $(this).click(function () {
      $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
    });
  });

  // set header height to sticky overlay
  $('.sticky-overlay').outerHeight($('.header').outerHeight());
}

function formScripts() {
  if ($('.form-dropdown')) {
    $('.form-dropdown').each(function() {
      $(this).select2({
        dropdownParent: $(this).parent().parent(),
      });
    });
  }

  $('.button-wrp-outter').append($('.wpcf7-response-output'));
}

function cardSectionGridSlider() {
  $('.cards-section.v1 .section-inner-wrp .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function cardSectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $(
    '.cards-section.v2 .section-inner-wrp .cards-wrp, .cards-section.v3 .section-inner-wrp .cards-wrp'
  ).flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });
}

function cardSectionGridSliderNew() {
  $('.cards-section-new.grid .section-inner-wrp .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function cardSectionSliderNew() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.cards-section-new.slider .section-inner-wrp .cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });
}

function gallerySectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.gallery-section.v1 .section-inner-wrp .gallery-wrapper').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    adaptiveHeight: true,
    cellAlign: cellAlignValue,
  });
}

// Implementation of Jquery Viewer
function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);

  $galleryImages.viewer({
    tooltip: 0,
    inline: false,
    toolbar: {
      zoomIn: {
        show: 1,
        size: 'large',
      },
      zoomOut: {
        show: 1,
        size: 'large',
      },
      oneToOne: 0,
      reset: 0,
      prev: {
        show: 1,
        size: 'large',
      },
      play: {
        show: 0,
        size: 'large',
      },
      next: {
        show: 1,
        size: 'large',
      },
      rotateLeft: 0,
      rotateRight: 0,
      flipHorizontal: 0,
      flipVertical: 0,
    },
    title: 0,
    viewed: function () {
      $galleryImages.viewer('zoomTo', 1);
    },
  });
}
// Implementation of Jquery Viewer

// Full Width Video Player
function fullWidthVideoPlayer() {
  for (let i = 1; i <= $('.playvideo').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo')[i-1].setAttribute('id', idName);
    $('.iframe-video')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){

      if ($('#'+newIdName).hasClass('video-element')) {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName).get(0).play();
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      } else {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName)[0].src += '&autoplay=1';
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      }
    });
  }
}
// Full Width Video Player

// Popup Video Player for Two Column Section
function videoPopupPlayer() {
  var videoSrc;
  var videoControls;
  $('.video-iframe-thumbnail').click(function () {
    videoSrc = $(this).data('src');
    videoControls = $(this).data('controls');
  });

  $('#myModal').on('shown.bs.modal', function () {
    $('#video').attr(
      'src',
      videoSrc + '?autoplay=1&mute=1&amp&modestbranding=1&amp&'+ videoControls+'&showinfo=0;'
    );
  });

  $('#myModal').on('hide.bs.modal', function () {
    $('#video').attr('src', videoSrc);
  });
}
// Popup Video Player for Two Column Section

// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-iframe').attr('src',$('#hidden-iframe-url').text());
    $('#home-banner-video').attr('src',$('#hidden-video-file').text());
  }, 3000);
}
// Delay Home Banner Video

//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor
function wrapButtons() {
  var target = '.btn',
      invert = ':not(' + target + ')',
      wrap = '<div class="button-wrapper">',
      breakpoints = $('.wrap-btns > *'+invert);

  $('.wrap-btns').each(function( ) {
    $(this).find('.btn').unwrap();
  });

  breakpoints.each(function(){
    $(this).nextUntil(invert).wrapAll(wrap);
  });

  breakpoints.first().prevUntil(invert).wrapAll(wrap);
}
//wrap all buttons with a wrapper to handle responsive - This works with "Add Buttons" feature in the wp editor

function logoSectionGridSlider() {
  $('.logo-section .logo-grid').flickity({
    pageDots: true,
    prevNextButtons: false,
    wrapAround: true,
    watchCSS: true,
  });
}

function logoSectionSlider(slideshowEl) {
  if ($('.logo-section .logo-slider').children().length > 6) {
    // Play with this value to change the speed
    let tickerSpeed = 1;

    let flickity = null;
    let isPaused = false;
    // const slideshowEl = document.querySelector('.logo-section .logo-slider');

    const update = () => {
      if (isPaused) return;
      if (flickity.slides) {
        flickity.x = (flickity.x - tickerSpeed) % flickity.slideableWidth;
        flickity.selectedIndex = flickity.dragEndRestingSelect();
        flickity.updateSelectedSlide();
        flickity.settle(flickity.x);
      }
      window.requestAnimationFrame(update);
    };

    const pause = () => {
      isPaused = true;
    };

    const play = () => {
      if (isPaused) {
        isPaused = false;
        window.requestAnimationFrame(update);
      }
    };

    flickity = new Flickity(slideshowEl, {
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      selectedAttraction: 0.015,
      friction: 0.25,
    });

    flickity.x = 0;

    slideshowEl.addEventListener('mouseenter', pause, false);
    slideshowEl.addEventListener('focusin', pause, false);
    slideshowEl.addEventListener('mouseleave', play, false);
    slideshowEl.addEventListener('focusout', play, false);

    flickity.on('dragStart', () => {
      isPaused = true;
    });

    update();
  }
}

function loadMore() {
  $('.news-item.left-items .news-item-inner').slice(0, 1).show();
  $('.news-item.right-items .news-item-inner').slice(0, 1).show();
  if ($('.news-item.left-items .news-item-inner:hidden').length != 0 || $('.news-item.right-items .news-item-inner:hidden').length != 0) {
    $('#loadMoreNews').show();
  } else {
    $('#loadMoreNews').hide();
  }
  $('#loadMoreNews').on('click', function (e) {
    e.preventDefault();
    $('.news-item.left-items .news-item-inner:hidden').slice(0, 1).slideDown();
    $('.news-item.right-items .news-item-inner:hidden').slice(0, 1).slideDown();
    if ($('.news-item.left-items .news-item-inner:hidden').length == 0) {
      $('#loadMoreNews').text('No More to view').fadeOut('slow');
      $('#loadMoreNews').addClass('load-more-end');
    }
  });


  $('.news-item.mobile-items .news-item-inner').slice(0, 2).show();
  if ($('.news-item.mobile-items .news-item-inner:hidden').length != 0) {
    $('#loadMoreNewsMobile').show();
  } else {
    $('#loadMoreNewsMobile').hide();
  }
  $('#loadMoreNewsMobile').on('click', function (e) {
    e.preventDefault();
    $('.news-item.mobile-items .news-item-inner:hidden').slice(0, 2).slideDown();
    if ($('.news-item.mobile-items .news-item-inner:hidden').length == 0) {
      $('#loadMoreNewsMobile').text('No More to view').fadeOut('slow');
      $('#loadMoreNewsMobile').addClass('load-more-end');
    }
  });
}

function testimonialGridSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.testimonials-section.grid .testimonials-row').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    watchCSS: true,
    cellAlign: cellAlignValue,
  });
}

function testimonialSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.testimonials-section.slider .testimonials-row').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    cellAlign: cellAlignValue,
  });
}